
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Cerapro";
  src: local("Cerapro"),
   url("./assets/fonts/cerapro/CeraProBlack.otf") format("truetype");
  /* font-weight: bold; */
 }

body,html,#root {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.banner-background{
  background-image: url("../src/assets/hero-image@2x.png");
  background-size: cover;
  height: 100vh;
  width: 100%;
  color:#FFF
}
