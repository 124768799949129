.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.section-how-it-works{
  /* background-color: rgba(24, 33, 40,0.2); */
}
.call-us-button{
  background-color: #353535;
  color:#FFF;
  
}
.feature-card{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.input-wrapper {
  position: relative;
  margin: 20px auto;
}

.form-control {
  display: block;
  line-height: 2em;
  margin: 0;
  padding-left: 10px;
  width: 100%;
  font-size: medium;
  border:none;
  border-bottom: 1px solid #f4f4f4;
  background-color: transparent;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 500;
  color: #FFF;
}

.form-control:focus {
  border-bottom: 1px solid #f4f4f4;
}

.control-label {
  display: block;
  position: absolute;
  opacity: 0;
  bottom: 1.9rem;
  color: #FFF;
  transition: 0.2s ease-in-out transform;
  font-size: 14px;
}

.form-control:placeholder-shown + .control-label {
  visibility: hidden;
  z-index: -1;
  transition: 0.2s ease-in-out;
}

.form-control:not(:placeholder-shown) + .control-label,
.form-control:focus:not(:placeholder-shown) + .control-label {
  visibility: visible;
  z-index: 1;
  opacity: 1;
  transform: translateY(-10px);
  transition: 0.2s ease-in-out transform;
}
.nav-shadow{
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}

.modal-content{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}